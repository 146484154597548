<template>
  <div>
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
      "
    >
      <el-button type="primary" size="small" @click="$router.back()"
        >返 回</el-button
      >
      <div style="display: flex; align-items: center">
        <el-select
          v-loading="loading"
          v-model="courseId"
          @change="changeSelect"
          :clearable="true"
          placeholder="请选择课程"
        >
          <el-option
            v-for="item in courseData"
            :key="item.id"
            :label="item.course_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
          type="primary"
          size="mini"
          style="margin-left: 20px"
          @click="search"
          >搜 索</el-button
        >
        <el-button size="mini" type="primary" @click="download">下载</el-button>
      </div>
    </div>

      <el-table
        :data="tableData"
        v-loading="tableLoading"
        height="650px"
        style="width: 100%;margin-top: 20px"
        fit
        :default-sort="{ prop: 'grade_count', order: 'descending' }"
      >
        <el-table-column
          type="index"
          label="序号"
          width="80"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="nickname"
          label="姓名"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="school_name"
          label="学校"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="last_answer_time"
          label="提交时间"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="course_name"
          label="所在课程"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="grade_count" width="180" label="成绩" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.grade_count+'/'+ scope.row.list_total_grade}}</span>
                </template>
              </el-table-column>

        <el-table-column
          v-for="(v, i) in headers"
          :key="i"
          :prop="v.title"
          :label="v.title"
          min-width="200"
          align="center"
        >
          <template #header>
            <el-tooltip :content="v.title" placement="top">
                    <div style="width: 150px;" v-html="v.title"></div>
                  </el-tooltip>
                  <div>总分:{{ v.grade }}</div>
          </template>
          <!-- <template slot="header">
            <el-tooltip :content="v">
              <span >{{ v }}</span>
            </el-tooltip>
          </template> -->
          <template slot-scope="scope">
            <div style="display: flex; justify-content: space-around">
                    <div>
                      {{ scope.row.question_list[i]?.grade }}
                    </div>
                    <el-button size="mini" type="primary" @click="checkCode(scope.row.question_list[i],scope.row)">查看答案</el-button>
                  </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-empty description="暂无数据"></el-empty> -->
      <div
        class="pagination"
        style="
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="currentChange"
          @size-change="handleSizeChange"
          :page-size="pageSize"
          :current-page="page"
        >
        </el-pagination>
      </div>
  </div>

  <!-- <question-census
      :columns="columns"
      table-url="/xapi/question.other/listInfo"
      census-url="/xapi/question.other/censusInfo"
  >
    <template slot="action" slot-scope="scope">
      <slot name="action" v-bind="scope">
        <el-button :disabled="!$isPowers('examEdit')" @click="$handleRoute({id:scope.row.other_id,log_id:scope.row.log_id},'matchTiDanRecord')" type="text" size="small">详情</el-button>
      </slot>
    </template>
  </question-census> -->
</template>

<script>
import QuestionCensus from "@/views/api/teaching/question/census";
export default {
  components: { QuestionCensus },
  data() {
    return {
      tableData: [],
      total: 0,
      page: 1,
      pageSize: 50,
      headers: [],
      end_time: "",
      other_id: 0,
      start_time: "",
      group_id: 0,
      tableLoading: false,
      loading: false,
      courseData: [],

      courseId: "",
      // columns: [
      //   { prop: "log_id", label: "ID", width: "80", align: "center" },
      //   {
      //     prop: "title",
      //     label:
      //       '<span><font style="color:red;">类型</font> - <font style="color:green;">创建人</font> - 题目名称</span>',
      //     template: (scope) => {
      //       return (
      //         '<font style="color:red;">' +
      //         (scope.row.matchStr || "") +
      //         '</font> - <font style="color:green;">' +
      //         (scope.row.create_user_name || "") +
      //         "</font> - " +
      //         this.$jquery("<font>" + scope.row.other_title + "</font>").text()
      //       );
      //     },
      //     minWidth: "320px",
      //     align: "center",
      //   },
      //   { prop: "member_name", label: "用户", width: "140", align: "center" },
      //   { prop: "grade", label: "得分", width: "80", align: "center" },
      //   { prop: "total_grade", label: "总分", width: "80", align: "center" },
      //   {
      //     prop: "answer_time",
      //     label: "答题时间",
      //     width: "80",
      //     align: "center",
      //   },
      //   {
      //     prop: "last_commit_date",
      //     label: "提交时间",
      //     width: "160",
      //     align: "center",
      //   },
      //   {
      //     fixed: "right",
      //     label: "操作",
      //     width: "80",
      //     slot: "action",
      //     align: "center",
      //   },
      // ],
    };
  },
  created() {
    console.log(this.$router.currentRoute, "this.$router.currentRoute");
    this.other_id =
      this.$router.currentRoute.query.other_id ||
      this.$router.currentRoute.query.id;
    this.group_id = this.$router.currentRoute.query.group_id;
    this.start_time = this.$router.currentRoute.query.start_time;
    this.end_time = this.$router.currentRoute.query.end_time;
    this.checkTotal();
    // 获取我管理的课程
    this.getMyCourse();
  },
  methods: {
    // 查看答题统计
    checkTotal() {
      this.tableLoading = true;
      this.$http
        .get("/api/v1_0_0.question_other/statistics_user", {
          params: {
            page_size: this.pageSize,
            page: this.page,
            other_id: this.other_id,
            group_id: this.group_id,
            start_time: this.start_time,
            end_time: this.end_time,
            course_id: this.courseId,
          },
        })
        .then((res) => {
          this.tableLoading = false;

          // console.log(res, "查看答题统计");
          this.tableData = res.data.list;
          this.total = res.data.total;
          // this.headers = res.data.question_title;
          this.headers = res.data.quest_title;
          // console.log(this.headers, "this.headersthis.headers");
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
     // 查看单个题目代码
     checkCode(item,e) {
      console.log(item,"item")
      // return
      this.$handleRoute({log_id:item.log_id,id:item.qid} , 'topicRecord' , '_blank')
      console.log(e, "eeeee");
    },
    // 获取我负责的课程
    getMyCourse() {
      this.loading = true;
      this.$http
        .post(
          `/xapi/course.course/myList?page=` +
            this.page +
            "&page_size=" +
            this.pageSize +
            "&is_up=1"
        )
        .then((res) => {
          this.loading = false;
          this.courseData = res.data.data;
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    //下载
    download() {
      window.location.href =
        (location.baseURL || "") +
        `/api/v1_0_0.question_other/rank_log_down?other_id=${
          this.other_id
        }&course_id=${this.course_id}&group_id=${
          this.group_id
        }&user_id=${localStorage.getItem("user_id")}&user_token=` +
        encodeURIComponent(localStorage.getItem("user_token"));
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.checkTotal();
    },
    currentChange(e) {
      this.page = e;
      this.checkTotal();
    },
    changeSelect(e) {
      console.log(e, "eeeeeeeee");
      this.courseId = e;
    },
    // 按课程筛选
    search() {
      this.checkTotal();
    },
  },
};
</script>

<style scoped lang="less">
::v-deep {
  .el-card__body {
  padding: 0px;
}
// .cell{
//   display: flex;
//     flex-direction: column;
//     align-items: center;
// }
}
</style>